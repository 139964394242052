.print-source {
    display: none;
}



.print-no {
    /* display: block !important */
}

.yes{
  background-color: green;
  color: white;
  font-size: 20px;
  padding: 1px;
  border: 1px solid #0a0a0a;
  cursor: pointer
}

.yes:hover{
  background-color: turquoise;
}

.no:hover{
  background-color: turquoise;
}

.no{
  background-color: red;
  color: white;
  
  font-size: 20px;
  padding: 1px;
  border: 1px solid #0a0a0a;
  cursor: pointer

}
.stock-availity{
  margin: 0;
  padding: 0;
  font-weight: bold;
  text-align: right;
  color:green
}

.stock-unavaility{
  margin: 0;
  padding: 0;
  font-weight: bold;
  text-align: right;
  color:red
}


tfoot tr td {
  font-weight: bold !important
}
.report-dom tr{
  border: 1px solid rgb(4 4 4) !important;
  color: #222  !important;
}

.report-dom tr td{
  border: 1px solid rgb(4 4 4) !important;
  color: #222  !important;
  padding: 1px 1px 1px 1px !important;
  font-size: 12px  !important;

}
.report-dom tr th{
  border: 1px solid rgb(4 4 4) !important;
  color: #000000 !important;
  background: #cecfce !important;
  padding: 1px;
  font-size: 14px  !important;

}
.report-dom tr:nth-child(even) {
  background: #ffffff;
}

.report-dom tr:nth-child(odd) {
  background: #fbfbfb;
}

.css-1uccc91-singleValue{
  color: white !important
}
  
  /* body > .print-source {
    display: block !important
  } */
  
  @media print {
    .print-no {
        display: none !important
    }

    .print-source {
      display: block;
      /* background-color: green; */
    }
  }